import React, { Component } from 'react';

class Contact extends Component {
    render() {
        return (
            <footer className="footer">
                <h4>© {new Date().getFullYear()} Felix Fernando Wijaya. All Rights Reserved.</h4>
            </footer>
        );
    }
}

export default Contact;