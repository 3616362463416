import React, { Component } from 'react'
import  './css/Main.css'

class Header extends Component {
    render() {
        return (
            <div id="home">   
                <h1 className="header-h1">WELCOME</h1>
            </div>
        )
    }
}

export default Header
